<template>
  <div class="nav-item">
      <el-scrollbar style="height:100%">
          <el-menu ref="menu" router :default-active="$route.path=='/main/UseWater/flowUrl'?'/simpleness?lat=37.544315&lon=117.705734':$route.path"  unique-opened @open="openSubmenu" @close="closeSubmenu" @select="selectMenu">
              <router-link to="/dataV" style="display:block;padding: 9px 46px;background: #FFFFFF;padding: 9px 31px">
                  <img src="@/assets/images/navDataV.png" alt="" style="width: 100%;height: 100%">
              </router-link>
              <div v-for="(item, index) of navItems" :key="index">
                <el-submenu v-if="item.children" :index="item.routeName">
                  <template slot="title">
                    <img v-if="!open.includes(item.routeName)" class="menu-icon" :src="require(`../../assets/images/navMenu/${item.uncheck}.png`)">
                    <img v-else class="menu-icon" :src="require(`../../assets/images/navMenu/${item.checked}.png`)">
                    <span class="menu-text">{{item.moduleName}}</span>
                  </template>
                  <el-menu-item :class="{ 'selected-submenu': subitem.routeName === $route.path }" v-for="(subitem, index) of item.children" :key="index" :index="subitem.routeName">
                    <span class="menu-index">{{index+1}}</span>
                    <span class="submenu-text" :class="subitem.routeName" :title="subitem.moduleName">{{subitem.moduleName}}</span>
                  </el-menu-item>
                </el-submenu>
                <el-menu-item :class="{ 'selected-menu': item.routeName === $route.path }" v-else :index="item.routeName">
                  <img v-if="item.routeName !== $route.path" class="menu-icon" :src="require(`../../assets/images/navMenu/${item.uncheck}.png`)">
                  <img v-else class="menu-icon" :src="require(`../../assets/images/navMenu/${item.checked}.png`)">
                  <span class="menu-text">{{item.moduleName}}</span>
                </el-menu-item>
            </div>
        </el-menu>
      </el-scrollbar>
  </div>
</template>

<script>
export default {
  props: {
    navItems: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      selected: 0,
      open: this.$route.path,
      flowmeterUrl:'',
    }
  },
  mounted(){
     // console.log( this.navItems);
    // console.log( this.$route.path);
    // let query = window.location.href;
    // let vars = query.split("#");
    // this.flowmeterUrl=vars[1]
    // console.log(vars);
  },
  methods: {
    // 展开二级菜单
    openSubmenu (e) {
      // console.log(e);
      this.open = e
      document.body.scrollIntoView
    },
    // 关闭二级菜单
    closeSubmenu () {
      this.open = ''
    },
    // 激活菜单
    selectMenu (e) {
      // console.log(e);
      const arr = e.split('/')
      if (arr.length <= 3) {
        this.open = ''
        for (const i of this.navItems) {
          if (i.children) {
            this.$refs.menu.close(i.routeName)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-item{
  width: 310px;
  height: 100%;
  overflow:hidden;
}
.el-menu{
  width: 290px;
  min-height: 100vh;

  .menu-icon{
    width: 20px;
    height: 20px;
    margin-right: 18px;
  }
  .menu-text{
    font-size: 22px;
    color: #004472;
  }
   .menu-index{
     margin-left: -18px;
    font-size: 18px;
    color: #004472;
  }
  .submenu-text{
    margin-left: 18px;
    font-size: 22px;
    color: #004472;
  }
  ::v-deep .el-submenu__icon-arrow{
    font-size: 16px;
    font-weight: bold;
    color: #1679bd;
  }
  .is-opened{
    ::v-deep .el-submenu__title{
      background: linear-gradient(90deg, #5693D2 0%, #1B6FC6 99%);
    }
    .menu-text{
      color: #fff;
    }
    ::v-deep .el-submenu__icon-arrow{
      color: #fff;
    }
  }
  .el-submenu{
    ::v-deep .el-menu{
      max-height: 300px;
       overflow-x:hidden;
    }
    .el-menu-item{
      background-color: #e1eefe;
    }
  }
  .selected-submenu{
    .submenu-text{
      color: #0196ff;
    }
  }
  .selected-menu{
    .menu-text{
      color: #fff;
    }
    background: linear-gradient(90deg, #5693D2 0%, #1B6FC6 99%);
  }
}
</style>
